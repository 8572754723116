<template>
  <div>
    <loader :myShow="myLoad" />
    <div class="row">
      <div class="col-lg-9">
        <ChartBar :labels="labels" :analytcsOfMonth="analytcsOfMonth" />
      </div>
      <div class="">
        <label for="" style="font-size: 30px; font-weight: bold;">Статистика месяца</label>
        <hr>
        <div v-for="(item, index) in forMonth">
         <div v-if="index<2"> <span style="font-weight: bold; font-size: 18px">{{index+1}} - </span>  <span  class="btn btn-success m-1" style="font-style: bold"> {{item.title}} - {{item.count}}</span> </div>
         <div v-else-if="index<5"> <span style="font-weight: bold; font-size: 18px">{{index+1}} - </span>  <span  class="btn btn-primary m-1" style="font-style: bold"> {{item.title}} - {{item.count}}</span> </div>
         <div v-else-if="index<7"> <span style="font-weight: bold; font-size: 18px">{{index+1}} - </span>  <span  class="btn btn-info m-1" style="font-style: bold"> {{item.title}} - {{item.count}}</span> </div>
         <div v-else-if="index<9"> <span style="font-weight: bold; font-size: 18px">{{index+1}} - </span>  <span  class="btn btn-warning m-1" style="font-style: bold"> {{item.title}} - {{item.count}}</span> </div>
         <div v-else> <span style="font-weight: bold; font-size: 18px">{{index+1}} - </span>  <span  class="btn btn-danger m-1" style="font-style: bold"> {{item.title}} - {{item.count}}</span> </div>
        </div>
      </div>
    </div>

    <CRow>
      <CCol sm="4" lg="2" v-for="item in forToday">
        <CWidgetSimple :header="item.title" :text="item.count + ' '">
          <!-- <CChartLineSimple style="height:40px" border-color="danger"/> -->
        </CWidgetSimple>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import ChartBar from '@/views/includes/ChartBar'

export default {
  name: 'Dashboard',
  components:{
    ChartBar
  },
  data () {
    return {
      analytcsOfMonth: [],
      labels: [],
      forMonth: [],
      forToday: [],
      selected: 'Month',
      myLoad: true,
      tableItems: [
        {
          avatar: { url: 'img/avatars/1.jpg', status: 'success' },
          user: { name: 'Yiorgos Avraamu', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'USA', flag: 'cif-us' },
          usage: { value: 50, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Mastercard', icon: 'cib-cc-mastercard' },
          activity: '10 sec ago'
        },
        {
          avatar: { url: 'img/avatars/2.jpg', status: 'danger' },
          user: { name: 'Avram Tarasios', new: false, registered: 'Jan 1, 2015' },
          country: { name: 'Brazil', flag: 'cif-br' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Visa', icon: 'cib-cc-visa' },
          activity: '5 minutes ago'
        },
        {
          avatar: { url: 'img/avatars/3.jpg', status: 'warning' },
          user: { name: 'Quintin Ed', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'India', flag: 'cif-in' },
          usage: { value: 74, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Stripe', icon: 'cib-stripe' },
          activity: '1 hour ago'
        },
        {
          avatar: { url: 'img/avatars/4.jpg', status: '' },
          user: { name: 'Enéas Kwadwo', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'France', flag: 'cif-fr' },
          usage: { value: 98, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'PayPal', icon: 'cib-paypal' },
          activity: 'Last month'
        },
        {
          avatar: { url: 'img/avatars/5.jpg', status: 'success' },
          user: { name: 'Agapetus Tadeáš', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'Spain', flag: 'cif-es' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Google Wallet', icon: 'cib-google-pay' },
          activity: 'Last week'
        },
        {
          avatar: { url: 'img/avatars/6.jpg', status: 'danger' },
          user: { name: 'Friderik Dávid', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'Poland', flag: 'cif-pl' },
          usage: { value: 43, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Amex', icon: 'cib-cc-amex' },
          activity: 'Last week'
        }
      ],
      tableFields: [
        { key: 'avatar', label: '', _classes: 'text-center' },
        { key: 'user' },
        { key: 'country', _classes: 'text-center' },
        { key: 'usage' },
        { key: 'payment', label: 'Payment method', _classes: 'text-center' },
        { key: 'activity' },
      ]
    }
  },
  methods: {
    getMonthAnalytics(){
      axios.get('/employee/analytics/month').then((response) => {
        if(response.status == 200){
            this.labels = response.data.dates;
            this.analytcsOfMonth = response.data.branchAnalytcs;
            this.forMonth = response.data.forMonth;
            this.forToday = response.data.forToday;
            console.log(this.forToday);
        }
      }).catch((error) => {
        this.$message.error(
          "Ошибка сервера",
          3
        );
      }).finally(() => {});
    },
    checkAuth(){
      axios.get('/user').then((response)=>{

      }).catch((error)=>{
        if(error.response.status===401){
            this.$router.push('/pages/login')
        }
      }).finally(() => {this.myLoad= false;});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  },
  mounted(){
    this.getMonthAnalytics();
    this.checkAuth();
  }
}
</script>
