<template>
  <CChartBar
    :datasets="analytcsOfMonth"
    :labels="labels"
  />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'CChartBarExample',
  components: { CChartBar },
  props: ['analytcsOfMonth', 'labels'],
  computed: {
    defaultDatasets () {
      return [
        {
          label: 'Андижан филиал',
          backgroundColor: '#f87979',
          data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
        },
        {
          label: 'Бухара филиал',
          backgroundColor: '#9B75EE',
          data: [15, 90, 22, 49, 50, 70, 21, 32, 43, 54, 65, 97]
        },
        {
          label: 'Навои филиал',
          backgroundColor: '#00FFA9',
          data: [12, 43, 54, 65, 67, 87, 87, 67, 45, 34, 23, 12]
        },
        {
          label: 'Наманган филиал',
          backgroundColor: '#318CE7',
          data: [42, 13, 23, 43, 67, 87, 89, 9, 34, 32, 21, 11]
        },
        {
          label: 'Сырдарья филиал',
          backgroundColor: '#50C878',
          data: [32, 34, 45, 56, 67, 76, 54, 43, 32, 21, 12, 2]
        },
        {
          label: 'Андижан филиал',
          backgroundColor: '#f87979',
          data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
        },
        {
          label: 'Бухара филиал',
          backgroundColor: '#9B75EE',
          data: [15, 90, 22, 49, 50, 70, 21, 32, 43, 54, 65, 97, 12, 13, 90]
        },
        {
          label: 'Навои филиал',
          backgroundColor: '#00FFA9',
          data: [12, 43, 54, 65, 67, 87, 87, 67, 45, 34, 23, 12]
        },
        {
          label: 'Наманган филиал',
          backgroundColor: '#318CE7',
          data: [42, 13, 23, 43, 67, 87, 89, 9, 34, 32, 21, 11]
        },
        {
          label: 'Сырдарья филиал',
          backgroundColor: '#50C878',
          data: [32, 34, 45, 56, 67, 76, 54, 43, 32, 21, 12, 2]
        },
        {
          label: 'Наманган филиал',
          backgroundColor: '#318CE7',
          data: [42, 13, 23, 43, 67, 87, 89, 9, 34, 32, 21, 11]
        },
        {
          label: 'Сырдарья филиал',
          backgroundColor: '#50C878',
          data: [32, 34, 45, 56, 67, 76, 54, 43, 32, 21, 12, 2]
        },
      ]
    }
  }
}
</script>
